// src/data/articles.js
const articles = [
  {
    id: 1,
    category: "custom-websites",
    title: "Cum îmi pot proteja site-ul împotriva atacurilor cibernetice?",
    slug: "cum-imi-pot-proteja-site-ul-impotriva-atacurilor-cibernetice",
    description:
      "Află măsuri de protecție pentru securitatea website-ului tău împotriva atacurilor cibernetice.",
    content:
      "Securitatea website-ului este esențială pentru a proteja atât datele afacerii, cât și pe cele ale utilizatorilor. Măsuri precum utilizarea unui firewall, actualizarea periodică a software-ului, instalarea unui certificat SSL și folosirea unor parole puternice sunt esențiale. Implementarea unui sistem de monitorizare poate ajuta la detectarea activităților suspecte și la prevenirea atacurilor de tip malware sau phishing.",
    keywords: [
      "securitate website",
      "protecție atacuri cibernetice",
      "firewall website",
      "certificat SSL",
      "prevenire malware",
    ],
    imageUrl:
      "https://tinypic.host/images/2024/11/08/Global-Connectivity-Work-on-Laptop.jpeg",
  },
  {
    id: 2,
    category: "custom-websites",
    title: "Am nevoie de un certificat SSL și de ce este acesta important?",
    slug: "am-nevoie-de-un-certificat-ssl-si-de-ce-este-acesta-important",
    description:
      "Află de ce certificatul SSL este important pentru securitatea și optimizarea website-ului tău.",
    content:
      "Certificatul SSL este o măsură esențială de securitate care asigură că toate informațiile transmise între utilizatori și server sunt criptate. Acesta este un standard important pentru securitate, îmbunătățește încrederea utilizatorilor și poate ajuta la poziționarea SEO. Motoarele de căutare, precum Google, prioritizează site-urile securizate.",
    keywords: [
      "certificat SSL",
      "securitate website",
      "criptare date",
      "SEO certificat SSL",
      "confidențialitate utilizatori",
    ],
    imageUrl:
      "https://tinypic.host/images/2024/11/08/Woman-in-Red-Data-Glow.jpeg",
  },
  {
    id: 3,
    category: "web-design-trends",
    title: "Ce înseamnă un design „responsive” și de ce este important?",
    slug: "ce-inseamna-un-design-responsive-si-de-ce-este-important",
    description:
      "Descoperă importanța unui design responsive pentru adaptarea site-ului la diverse dispozitive.",
    content:
      "Designul responsive este o abordare care permite site-ului să se adapteze la dimensiunea oricărui ecran, fie el de desktop, tabletă sau smartphone. Cu un design responsive, vizitatorii vor avea o experiență de navigare optimă, indiferent de dispozitiv. Acest lucru este esențial, deoarece majoritatea traficului online provine de pe dispozitive mobile.",
    keywords: [
      "design responsive",
      "experiență utilizator",
      "optimizare mobil",
      "design adaptabil",
      "website mobil",
    ],
    imageUrl:
      "https://tinypic.host/images/2024/11/08/Person-with-Smartphone.jpeg",
  },
  {
    id: 4,
    category: "seo-tips",
    title: "Cum pot îmbunătăți timpul de încărcare al paginilor site-ului meu?",
    slug: "cum-pot-imbunatati-timpul-de-incarcare-al-paginilor-site-ului-meu",
    description:
      "Sfaturi utile pentru optimizarea vitezei de încărcare a paginilor website-ului tău.",
    content:
      "Viteza de încărcare a paginilor influențează atât experiența utilizatorilor, cât și poziționarea în motoarele de căutare. Îmbunătățirea timpului de încărcare poate fi realizată prin optimizarea imaginilor, folosirea unei rețele de distribuție de conținut (CDN), minimizarea codului CSS și JavaScript și activarea funcției de caching.",
    keywords: [
      "optimizare viteză",
      "timp de încărcare pagină",
      "caching website",
      "optimizare imagini",
      "website rapid",
    ],
    imageUrl:
      "https://tinypic.host/images/2024/11/08/Work-Session-at-a-Coffee-Shop-with-Holiday-Elements.jpeg",
  },
  {
    id: 5,
    category: "UX-UI",
    title: "Care sunt cele mai bune practici pentru navigarea pe site?",
    slug: "care-sunt-cele-mai-bune-practici-pentru-navigarea-pe-site",
    description:
      "Învață cum să creezi o experiență de navigare intuitivă și ușoară pentru utilizatori.",
    content:
      "Navigarea pe un website ar trebui să fie intuitivă și ușor de folosit. Este recomandat să folosești un meniu clar, link-uri evidente și să organizezi conținutul în mod logic. Asigură-te că utilizatorii pot găsi rapid informațiile esențiale și că fiecare pagină este accesibilă din două sau trei clicuri.",
    keywords: [
      "navigare website",
      "UX navigare",
      "structură website",
      "meniu site",
      "link-uri navigație",
    ],
    imageUrl:
      "https://tinypic.host/images/2024/11/08/Modern-Workspace-with-Tech-and-Personal-Touches.jpeg",
  },
  {
    id: 6,
    category: "seo-tips",
    title: "Cum pot crea conținut de calitate pentru site-ul meu?",
    slug: "cum-pot-crea-continut-de-calitate-pentru-site-ul-meu",
    description:
      "Află cum să creezi conținut relevant și valoros care atrage și fidelizează vizitatorii.",
    content:
      "Crearea de conținut relevant, original și valoros este esențială pentru succesul unui website. Conținutul de calitate atrage utilizatorii, construiește autoritatea în domeniu și ajută la optimizarea SEO. Asigură-te că folosești cuvinte-cheie relevante, informații utile și formatează textul pentru lizibilitate.",
    keywords: [
      "conținut de calitate",
      "optimizare SEO",
      "cuvinte-cheie website",
      "conținut original",
      "relevanță website",
    ],
    imageUrl:
      "https://tinypic.host/images/2024/11/08/Casual-Tablet-Reader-Engaging-Digital-Magazine-with-Vivid-Red-Design.jpeg",
  },
  {
    id: 7,
    category: "custom-websites",
    title:
      "Este mai bine să folosesc imagini de stoc sau să investesc în imagini originale?",
    slug: "este-mai-bine-sa-folosesc-imagini-de-stoc-sau-sa-investesc-in-imagini-originale",
    description:
      "Descoperă avantajele și dezavantajele imaginilor de stoc versus imagini originale.",
    content:
      "Imaginile de stoc sunt accesibile și rapide, dar nu oferă o notă unică. Imaginile originale creează o legătură autentică cu brandul și contribuie la un aspect profesional al site-ului. În funcție de buget, o combinație de imagini personalizate și de stoc poate aduce valoare site-ului.",
    keywords: [
      "imagini website",
      "imagini de stoc",
      "imagini originale",
      "conținut vizual website",
      "branding imagini",
    ],
    imageUrl:
      "https://tinypic.host/images/2024/11/08/Creative-Workspace-with-Photographer-at-Work.jpeg",
  },
  {
    id: 8,
    category: "custom-websites",
    title: "Cum pot integra funcționalități de e-commerce pe site-ul meu?",
    slug: "cum-pot-integra-functionalitati-de-e-commerce-pe-site-ul-meu",
    description:
      "Află cum să transformi site-ul tău într-un magazin online funcțional și eficient.",
    content:
      "Integrarea e-commerce permite vânzarea online de produse și servicii. Este necesar un sistem de management al comenzilor, un proces de checkout sigur și opțiuni variate de plată. Soluții populare includ WooCommerce (pentru WordPress), Shopify și Magento.",
    keywords: [
      "e-commerce website",
      "integrare magazin online",
      "platformă e-commerce",
      "vânzare online",
      "soluții e-commerce",
    ],
    imageUrl:
      "https://tinypic.host/images/2024/11/08/Stylish-Retail-Store-Interior-with-POS-System.jpeg",
  },
  {
    id: 9,
    category: "custom-websites",
    title: "Ce opțiuni de plată online ar trebui să ofer clienților mei?",
    slug: "ce-optiuni-de-plata-online-ar-trebui-sa-ofer-clientilor-mei",
    description:
      "Descoperă metodele de plată preferate de clienți pentru a facilita conversiile online.",
    content:
      "Oferirea de opțiuni de plată diverse sporește șansele de conversie. Include metode populare precum cardurile de credit, PayPal și plăți prin telefon mobil. De asemenea, opțiunile de plată localizate pot ajuta afacerea să fie mai atractivă pentru publicul românesc.",
    keywords: [
      "opțiuni plată online",
      "procesare plăți",
      "plăți digitale",
      "metode plată website",
      "soluții plată",
    ],
    imageUrl:
      "https://tinypic.host/images/2024/11/08/Hand-Holding-Various-Banknotes.jpeg",
  },
  {
    id: 10,
    category: "seo-tips",
    title:
      "Cum îmi pot gestiona comentariile și recenziile utilizatorilor pe site?",
    slug: "cum-imi-pot-gestiona-comentariile-si-recenziile-utilizatorilor-pe-site",
    description:
      "Învață cum să moderezi și să afișezi recenziile și comentariile utilizatorilor pentru un site de încredere.",
    content:
      "Comentariile și recenziile oferă un feedback autentic și pot îmbunătăți încrederea vizitatorilor în brand. Platformele precum WordPress oferă pluginuri pentru moderarea comentariilor. Afișarea recenziilor poate îmbunătăți SEO și atrage mai mulți clienți.",
    keywords: [
      "gestionare comentarii",
      "recenzii website",
      "moderare comentarii",
      "recenzii clienți",
      "feedback utilizatori",
    ],
    imageUrl:
      "https://tinypic.host/images/2024/11/08/How-To-Get-More-Google-Reviews.webp",
  },
];

export default articles;
