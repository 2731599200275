// src/pages/Home.js
import React from "react";
import Slideshow from "../components/Slideshow";
import CategoryList from "../components/CategoryList";
import logo from "../assets/upvm.webp";
import "../styling/Home.css";

function Home() {
  return (
    <div className="page-container home-container">
      <header className="header">
        <img src={logo} alt="UpVisionMedia Logo" className="logo" />
      </header>
      <Slideshow />
      <CategoryList />
    </div>
  );
}

export default Home;
