// src/components/CategoryList.js
import React from "react";
import { Link } from "react-router-dom";
import "../styling/CategoryList.css";

function CategoryList() {
  const categories = [
    "Custom Websites",
    "SEO Tips",
    "Web Design Trends",
    "UX-UI",
  ];

  return (
    <div className="category-list">
      <h3>Categorii</h3>
      <div className="category-buttons">
        {categories.map((category) => (
          <Link
            to={`/category/${category.toLowerCase().replace(/\s+/g, "-")}`}
            key={category}
            className="category-button">
            {category}
          </Link>
        ))}
      </div>
    </div>
  );
}

export default CategoryList;
