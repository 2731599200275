// src/components/Slideshow.js
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import articles from "../data/articles";
import "../styling/Slideshow.css";

function Slideshow() {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === articles.length - 1 ? 0 : prevIndex + 1
      );
    }, 6000); // Change slide every 6 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="slideshow-container">
      {articles.map((article, index) => (
        <div
          key={article.id}
          className={`slide ${index === currentIndex ? "active" : ""}`}>
          <Link to={`/article/${article.slug}`} className="slide-link">
            <img
              src={article.imageUrl}
              alt={article.title}
              className="slide-image"
            />
            <div className="slide-info">
              <h3>{article.title}</h3>
              <p>{article.description}</p>
            </div>
          </Link>
        </div>
      ))}
    </div>
  );
}

export default Slideshow;
