// src/pages/CategoryPage.js
import React from "react";
import { useParams, Link } from "react-router-dom";
import articles from "../data/articles";
import "../styling/CategoryPage.css";

function CategoryPage() {
  const { name } = useParams();
  const filteredArticles = articles.filter(
    (article) => article.category.toLowerCase() === name.toLowerCase()
  );

  return (
    <div className="page-container category-page">
      <h2 className="category-title">Categorie: {name}</h2>
      {filteredArticles.length > 0 ? (
        <ul className="article-list">
          {filteredArticles.map((article) => (
            <li key={article.id} className="article-item">
              <Link to={`/article/${article.slug}`} className="article-link">
                <h3>{article.title}</h3>
                <p>{article.description}</p>
              </Link>
            </li>
          ))}
        </ul>
      ) : (
        <p className="no-articles">Nu există articole în această categorie.</p>
      )}
      <Link to="/" className="back-home-button">
        Back Home
      </Link>
    </div>
  );
}

export default CategoryPage;
