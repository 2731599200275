// src/pages/ArticlePage.js
import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import articles from "../data/articles";
import "../styling/ArticlePage.css";
import Logo from "../assets/upvm.webp";

function ArticlePage() {
  const { slug } = useParams();
  const navigate = useNavigate();

  // Find the article based on the slug
  const article = articles.find((article) => article.slug === slug);

  if (!article) {
    console.error("Article not found for slug:", slug);
    return <p>Articolul nu a fost găsit.</p>;
  }

  const today = new Date();
  const publicationDate = today.toLocaleDateString("ro-RO", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });

  const author = "upvisionmedia.com";

  return (
    <div className="page-container article-page">
      <img className="logo-article" src={Logo} alt="upvisionmedia.com" />
      <h1 className="article-title">{article.title}</h1>
      <p className="article-meta">
        <span className="article-date">{publicationDate}</span> |{" "}
        <a
          href="https://upvisionmedia.com"
          target="_blank"
          rel="noopener noreferrer"
          className="article-author">
          {author}
        </a>
      </p>
      <p className="article-description">{article.description}</p>
      <div className="article-content">
        <p>{article.content}</p>
      </div>
      <img
        src={article.imageUrl}
        alt={article.title}
        className="article-image"
      />

      <button onClick={() => navigate("/")} className="back-home-button">
        Înapoi la Acasă
      </button>
    </div>
  );
}

export default ArticlePage;
